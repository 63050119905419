import React from 'react'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'

const LowerThirdOne = (obj) => {


    const isInitialMount = useRef(true);

    const [preview, setPreview] = useState(obj.obj.preview);
    const [previewInitial, setPreviewInitial] = useState(obj.obj.preview)
    const [name, setName] = useState(obj.obj.name)
    const [prof, setProf] = useState(obj.obj.prof)
    const [pos, setPos] = useState([obj.obj.xpos, obj.obj.ypos])

    useEffect(() => {
        setPreview(obj.obj.preview)
        if (isInitialMount.current) {
        } else {

            setPreviewInitial(true)
            if (obj.obj.preview) {
                setName(obj.obj.name)
                setProf(obj.obj.function)
                setPos([obj.obj.xpos, obj.obj.ypos])
            }

            gsap.fromTo(document.getElementById("lrdPreview_1"),
                { x: preview ? '0px' : '-1920px', },
                { x: preview ? '-1920px' : '0px', duration: 2, ease: 'power4.inOut' }
            );
        }
    }, [obj.obj.preview])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setName(obj.obj.name)
            gsap.fromTo(document.getElementById("nameDiv_1"),
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.name])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setPos([obj.obj.xpos, obj.obj.ypos])
        }
    }, [obj.obj.xpos, obj.obj.ypos])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
            isInitialMount.current = false;
        } else {
            setProf(obj.obj.function)
            gsap.fromTo(document.getElementById("functionDiv_1"),
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.function])

    return (
        <div id="lrdPreview_1" className={`absolute ${previewInitial ? `` : `hidden`} px-5 py-2.5 h-14 justify-center items-center gap-5 flex`}
            style={{
                backgroundColor: `rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`,
                border: `1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`,
                borderRadius: `${obj.obj.rounded}px`,
                position: `absolute`,
                left: `${pos[0]}px`, top: `${pos[1]}px`
            }}>
            {obj.obj.icon.length !== 0 &&
                <div className={`w-5 h-5 text-center text-xl items-center inline-flex`}
                    style={{
                        color: `rgba(${obj.obj.iconcolor.rgb.r}, ${obj.obj.iconcolor.rgb.g}, ${obj.obj.iconcolor.rgb.b}, ${obj.obj.iconcolor.rgb.a})`
                    }}>
                    <i className={`fa-solid fa-${obj.obj.icon}`}></i>
                </div>}
            {obj.obj.name.length !== 0 &&
                <div className={`text-2xl font-semibold`}
                    id="nameDiv_1"
                    style={{
                        color: `rgba(${obj.obj.namecolor.rgb.r}, ${obj.obj.namecolor.rgb.g}, ${obj.obj.namecolor.rgb.b}, ${obj.obj.namecolor.rgb.a})`,
                    }}>
                    {name}
                </div>}
            {obj.obj.function.length !== 0 &&
                <div className={`text-2xl font-normal`}
                    id="functionDiv_1"
                    style={{
                        color: `rgba(${obj.obj.functioncolor.rgb.r}, ${obj.obj.functioncolor.rgb.g}, ${obj.obj.functioncolor.rgb.b}, ${obj.obj.functioncolor.rgb.a})`
                    }}>
                    {prof}
                </div>}
        </div>
    )
}

export default LowerThirdOne


