import React from 'react'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'

const SoMe = (obj) => {


    const isInitialMount = useRef(true);

    const previewDiv = document.getElementById('previewDiv');
    const tweetImg = document.getElementById('tweetImg');
    const nameDiv = document.getElementById('nameDiv');
    const usernameDiv = document.getElementById('usernameDiv');
    const dateDiv = document.getElementById('dateDiv');
    const textDiv = document.getElementById('textDiv');

    const [preview, setPreview] = useState(obj.obj.preview);
    const [previewInitial, setPreviewInitial] = useState(obj.obj.preview)
    const [name, setName] = useState(obj.obj.name.text);
    const [username, setUsername] = useState(obj.obj.username.text);
    const [date, setDate] = useState(obj.obj.date.text);
    const [text, setText] = useState(obj.obj.text.text);
    const [img, setImg] = useState(obj.obj.img);


    useEffect(() => {
        setPreview(obj.obj.preview)
        if (isInitialMount.current) {
        } else {
            
            if (obj.obj.preview) {
                setImg(obj.obj.img)
                setName(obj.obj.name.text)
                setUsername(obj.obj.username.text)
                setDate(obj.obj.date.text);
                setText(obj.obj.text.text);
            }

            setPreviewInitial(true)
            gsap.fromTo(previewDiv,
                { x: preview ? '0%' : '-100%', },
                { x: preview ? '-100%' : '0%', duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.preview])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setImg(obj.obj.img)
            gsap.fromTo(tweetImg,
                { opacity: 0, },
                { opacity: 100, duration: 5, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.img])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setName(obj.obj.name.text)
            gsap.fromTo(nameDiv,
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.name.text])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setUsername(obj.obj.username.text)
            gsap.fromTo(usernameDiv,
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.username.text])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setDate(obj.obj.date.text);
            gsap.fromTo(dateDiv,
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.date.text])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
            isInitialMount.current = false;
        } else {
            setText(obj.obj.text.text);
            gsap.fromTo(textDiv,
                { opacity: 0, },
                { opacity: 100, duration: 3, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.text.text])




    return (
        <div id="previewDiv" className={`${previewInitial ? `` : `hidden`} absolute left-0 top-0 w-[1920px] h-[1080px] 
        flex-col 
        justify-center items-center inline-flex
        `}
            style={{
                background: `linear-gradient(to right, rgba(${obj.obj.colorl.rgb.r}, ${obj.obj.colorl.rgb.g}, ${obj.obj.colorl.rgb.b}, ${obj.obj.colorl.rgb.a}), rgba(${obj.obj.colorr.rgb.r}, ${obj.obj.colorr.rgb.g}, ${obj.obj.colorr.rgb.b}, ${obj.obj.colorr.rgb.a}))`
            }}>
            <div className="self-stretch justify-start items-center inline-flex">
                <div className="flex-col justify-center inline-flex w-4/12 items-center">
                    <div id="tweetImg" className={`w-[360px] h-[360px] 
                    rounded-full 
                    flex-col justify-center items-center flex 
                    text-7xl text-neutral-50 
                    overflow-clip`}>
                        <img src={img} alt="" className="" />
                    </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
                    <div className="self-stretch p-2.5 flex-col justify-center items-start flex">
                        <div id="nameDiv" className={`w-[1100px] text-7xl font-black`}
                            style={{
                                color: `rgba(${obj.obj.name.color.rgb.r}, ${obj.obj.name.color.rgb.g}, ${obj.obj.name.color.rgb.b}, ${obj.obj.name.color.rgb.a})`
                            }}>
                            {name}
                        </div>
                        <div id="usernameDiv" className={`text-4xl font-normal mb-2`}
                            style={{
                                color: `rgba(${obj.obj.username.color.rgb.r}, ${obj.obj.username.color.rgb.g}, ${obj.obj.username.color.rgb.b}, ${obj.obj.username.color.rgb.a})`
                            }}>
                            {username}
                        </div>
                        <div id="dateDiv" className={`text-${obj.obj.date.color} text-2xl font-normal `}
                            style={{
                                color: `rgba(${obj.obj.date.color.rgb.r}, ${obj.obj.date.color.rgb.g}, ${obj.obj.date.color.rgb.b}, ${obj.obj.date.color.rgb.a})`
                            }}>
                            {date}
                        </div>
                    </div>
                    <div className={`self-stretch p-2.5 flex-col justify-center items-start`}>
                        <div id="textDiv" className={`w-[1100px] text-5xl font-normal `}
                            style={{
                                color: `rgba(${obj.obj.text.color.rgb.r}, ${obj.obj.text.color.rgb.g}, ${obj.obj.text.color.rgb.b}, ${obj.obj.text.color.rgb.a})`
                            }}>
                            {text.split("\n").map(str => <p className='mb-6'>{str}</p>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-[200px]" />
        </div>
    )
}


export default SoMe


