import React from 'react'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'



const Ticker = (obj) => {

    const isInitialMount = useRef(true);

    const previewDiv = document.getElementById('tickerDiv');
    const [preview, setPreview] = useState(obj.obj.preview);
    const [previewInitial, setPreviewInitial] = useState(obj.obj.preview);
    const [presentedText, setPresentedText] = useState('')
    const [intervals, setIntervals] = useState([]);
    const [rssAnim, setRssAnim] = useState(obj.obj.rss.preview)

    const [ghostText, setGhostText] = useState("")

    const [curText, setCurText] = useState(obj.obj.text.value)
    const [curCTA, setCurCTA] = useState(obj.obj.cta.value)

    useEffect(() => {
        setPreview(obj.obj.preview)
        if (isInitialMount.current) {
        } else {
            if (obj.obj.preview) {
                setCurText(obj.obj.text.value)
                setCurCTA(obj.obj.cta.value);
            }
            setPreviewInitial(true)
            gsap.fromTo(previewDiv,
                { y: preview ? '0%' : '200%', },
                { y: preview ? '200%' : '0%', duration: 1, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.preview])

    useEffect(() => {

        if (!obj.obj.preview || isInitialMount.current) {
        } else {
            // Clear any older intervals if obj.obj.rss.preview is false
            if (!rssAnim) {
                intervals.forEach(interval => clearInterval(interval));
                setIntervals([]);
                setGhostText(curText)

                setCurText(obj.obj.text.value)
                gsap.fromTo(document.getElementById('ghostTextDiv'),
                    { y: '0', opacity: 100 },
                    { y: '-200%', opacity: 0, duration: 1, ease: 'expo.out' }
                );
                gsap.fromTo(document.getElementById('curTextDiv'),
                    { y: '200%', opacity: 0 },
                    { y: '0%', opacity: 100, duration: 1, ease: 'expo.out' }
                );

                return;
            }

            // Fetch data from the given URL and update state based on the response
            const fetchData = async () => {
                try {
                    const response = await fetch(`/api/rss?url=${obj.obj.rss.url}`);
                    const data = await response.json();

                    let index = 0;

                    // Create an interval to change the state of presentedText every 2 seconds
                    const interval = setInterval(() => {
                        setPresentedText(data.items[index].title);
                        index++;

                        // If the end of the array is reached, clear the interval and restart with a new call
                        if (index === data.items.length) {
                            clearInterval(interval);
                            fetchData(); // Restart the process by recalling the URL
                        }
                    }, 6000);

                    // Store the interval ID in state to manage the interval
                    setIntervals([interval]);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            // Call fetchData function when obj.obj.rss.preview becomes true
            if (rssAnim) {
                fetchData();
            }

            // Clear intervals and reset state when the component unmounts or rssAnim becomes false
            return () => {
                intervals.forEach(interval => clearInterval(interval));

            };
        }
    }, [rssAnim, obj.obj.rss.url]);

    useEffect(() => {
        setRssAnim(obj.obj.rss.preview)
    }, [obj.obj.rss.preview])


    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setCurText(obj.obj.text.value)
            setGhostText(curText)
            gsap.fromTo(document.getElementById('ghostTextDiv'),
                { y: '0', opacity: 100 },
                { y: '-200%', opacity: 0, duration: 1, ease: 'expo.out' }
            );
            gsap.fromTo(document.getElementById('curTextDiv'),
                { y: '200%', opacity: 0 },
                { y: '0%', opacity: 100, duration: 1, ease: 'expo.out' }
            );
        }
    }, [obj.obj.text.value])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setCurText(presentedText)
            setGhostText(curText)
            gsap.fromTo(document.getElementById('ghostTextDiv'),
                { y: '0', opacity: 100 },
                { y: '-200%', opacity: 0, duration: 1, ease: 'expo.out' }
            );
            gsap.fromTo(document.getElementById('curTextDiv'),
                { y: '200%', opacity: 0 },
                { y: '0%', opacity: 100, duration: 1, ease: 'expo.out' }
            );
        }
    }, [presentedText])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
            isInitialMount.current = false;
        } else {
            setCurCTA(obj.obj.cta.value);
            gsap.fromTo(document.getElementById('ctaTextDiv'),
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.cta.value])

    return (<>
        <div id="tickerDiv" className={`${previewInitial ? `` : `hidden`} px-5 absolute justify-start items-center gap-2.5 inline-flex overflow-clip`}
            style={{
                backgroundColor: `rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`,
                border: `1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`,
                borderRadius: `${obj.obj.rounded}px`,
                position: `absolute`,
                left: `50px`, top: `984px`,
                width: `1820px`,
                height: `56px`
            }}>
            <div className={`grow shrink basis-0 justify-start items-center gap-2.5 inline-flex`}>
                <div className={`grow shrink justify-start items-center inline-flex py-1 overflow-clip`}>
                    <div className={`w-5 h-5 text-center text-xl items-center inline-flex`}
                        style={{
                            color: `rgba(${obj.obj.icon.color.rgb.r},${obj.obj.icon.color.rgb.g},${obj.obj.icon.color.rgb.b},${obj.obj.icon.color.rgb.a})`
                        }}>
                        <i className={`fa-solid fa-${obj.obj.icon.awCode}`}></i>
                    </div>
                    <div id="curTextDiv" className={`absolute left-12 text-xl font-semibold`}
                        style={{
                            color: `rgba(${obj.obj.text.color.rgb.r},${obj.obj.text.color.rgb.g},${obj.obj.text.color.rgb.b},${obj.obj.text.color.rgb.a})`
                        }}>
                        {curText}
                    </div>
                    <div id="ghostTextDiv" className={`absolute left-12 text-xl font-semibold`}
                        style={{
                            color: `rgba(${obj.obj.text.color.rgb.r},${obj.obj.text.color.rgb.g},${obj.obj.text.color.rgb.b},${obj.obj.text.color.rgb.a})`
                        }}>
                        {ghostText}
                    </div>
                </div>
            </div>
            <div id="ctaTextDiv" className={`px-0 justify-end items-center`}>
                {<div className={`text-${obj.obj.cta.color} text-lg font-black items-center inline-flex`}
                    style={{
                        color: `rgba(${obj.obj.cta.color.rgb.r},${obj.obj.cta.color.rgb.g},${obj.obj.cta.color.rgb.b},${obj.obj.cta.color.rgb.a})`
                    }}>
                    {curCTA}
                </div>}

            </div>
            <div className={`px-2.5 justify-end items-center inline-flex`}>
                <div className={`text-right text-xl font-normal`}
                    style={{
                        color: `rgba(${obj.obj.icon.color.rgb.r},${obj.obj.icon.color.rgb.g},${obj.obj.icon.color.rgb.b},${obj.obj.icon.color.rgb.a})`
                    }}>
                    {obj.obj.soMe.fb && <i className="fa-brands fa-square-facebook"></i>} {obj.obj.soMe.x && <i className="fa-brands fa-square-x-twitter"></i>} {obj.obj.soMe.yt && <i className="fa-brands fa-square-youtube"></i>} {obj.obj.soMe.ig && <i className="fa-brands fa-square-instagram"></i>}
                </div>
            </div>
        </div>

    </>
    )
}

export default Ticker