import React from "react";

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>

      <nav className="flex flex-wrap items-center justify-between px-0 py-2 bg-gradient-to-t from-neutral-950 to-neutral-900 font-['Poppins']">
        <div className="w-11/12 2xl:w-6/12 xl:w-6/12 lg:w-4/5 md:w-4/5 sm:w-11/12 xs:w-11/12 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">

            <a
              className="text-2xl font-bold leading-relaxed inline-block mr-0 py-0 whitespace-nowrap"
              href="/"
            >
              <i className="fa-solid fa-bars-progress text-neutral-50 text-2xl pr-4 "></i>
              <span className="text-neutral-50">stream-kit</span><span className="text-neutral-800 text-xl ml-4">beta</span>
            </a>
            {/* <button
                className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}>
                <i className="fas fa-bars"></i>
              </button> */}
          </div>
          {/* <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="/create"
                >
                  <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Share</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href=""
                >
                  <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i><span className="ml-2">Tweet</span>
                </a>
              </li>

            </ul>
          </div> */}
        </div>
      </nav>

    </>
  );
}