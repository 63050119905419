import React from 'react'
import { useParams } from 'react-router-dom';
import io from "socket.io-client";
import { useState, useEffect } from 'react'
import Ticker from './Components/Ticker';
import LiveLabel from './Components/LiveLabel';
import LowerThirdOne from './Components/LowerThirdOne';
import LowerThirdTwo from './Components/LowerThirdTwo';
import Stripe from './Components/Stripe';
import Grad from './Components/Grad';
import Slide from './Components/Slide';
import SoMe from './Components/SoMe';
import Splits from './Components/Splits';
// import Box from './Components/Box';

const socket = io.connect("/");

const T1Preview = () => {

    const { id } = useParams();

    const [updateDBdoc, setUpdateDBdoc] = useState(false)
    const [screenData, setScreenData] = useState(null)

    const connectedUser = () => {
        socket.emit('connectedUser', id);
    };

    socket.on("screenUpdate", (data) => {
        if (data === id) { setUpdateDBdoc(true) }
    });

    // useEffect(() => {
    //     socket.on("screenUpdate", (data) => {
    //         if (data === id) { setUpdateDBdoc(true) }
    //     });

    // }, [id]);

    useEffect(() => {
        const startScreen = async () => {
            fetch(`/api/scr?id=${id}`, {
                method: "GET"
            })
                .then(async response => {
                    const incomingScreen = await response.json()
                    return setScreenData(incomingScreen)

                })
                .catch(error => {
                    return console.log(error.message)
                });
        }
        setUpdateDBdoc(false)
        startScreen()
    }, [updateDBdoc, id])


    useEffect(() => {
        connectedUser()
    })

    return (<>
        {screenData &&
            <div id="screen"
                style={{
                    fontFamily: screenData.font,
                    position: `absolute`,
                    width: `1920px`,
                    height: `1820px`,
                    overflow: `clip`
                }}>
                <Splits obj={screenData.splits} />
                <SoMe obj={screenData.tweet} />
                <Slide obj={screenData.slide} />
                <Grad obj={screenData.grad} />
                <LiveLabel obj={screenData.liveLabel} />
                <Stripe obj={screenData.stripe} />
                <Ticker obj={screenData.ticker} />
                <LowerThirdOne obj={screenData.personOne} />
                <LowerThirdTwo obj={screenData.personTwo} />
                {/* <Box obj={screenData.stripe} /> */}
                {/* <StripeAdv obj={screenData.stripe} /> */}
            </div>

        }
    </>
    )
}

export default T1Preview



// animate__animated animate__fadeOut animate__delay-5s