import React from 'react'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'

const Grad = (obj) => {

    const isInitialMount = useRef(true);

    const [preview, setPreview] = useState(obj.obj.preview);
    const [previewInitial, setPreviewInitial] = useState(obj.obj.preview)

    useEffect(() => {
        setPreview(obj.obj.preview)
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setPreviewInitial(true)
            gsap.fromTo(document.getElementById('gradDiv'),
                { opacity: preview ? '100%' : '0%', },
                { opacity: preview ? '0%' : '100%', duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.preview])

    return (
        <div id="gradDiv"
            className={`${previewInitial ? `` : `hidden`} w-[1920px] h-[360px] bottom-[0px] absolute `}
            style={{
                background: `linear-gradient(to bottom, rgba(0, 0, 0, 0), 
                rgba(${obj.obj.color.rgb.r}, ${obj.obj.color.rgb.g}, ${obj.obj.color.rgb.b}, ${obj.obj.color.rgb.a}))`
            }} />
    )
}

export default Grad