import React from 'react'
import { useState, useEffect } from 'react'

const Splits = (obj) => {

    const [rgba, setRgba] = useState(`rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`)
    const [brdrCol, setBrdrCol] = useState(`1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`)
    const [roundeness, setRoundness] = useState(obj.obj.rounded)

    useEffect(() => {
        setRgba(`rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`)
    }, [obj.obj.bgcolor])

    useEffect(() => {
        setBrdrCol(`1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`)
    }, [obj.obj.bordercolor])

    useEffect(() => {
        setRoundness(`1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`)
    }, [obj.obj.rounded])

    return (
        <>
            {obj.obj.split === "Display & Camera" &&
                <div name="Split 2 Monitor & Cam" className={`w-[1920px] h-[1080px] ${obj.obj.preview ? `` : `hidden`}`}>
                    <div style={{
                        width: 1280, height: 720,
                        right: 50, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 516, height: 290.25,
                        left: 50, top: 480,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{ width: 1920, height: 50, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 590, height: 480, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 1870, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 1920, height: 310, left: 0, top: 770, position: 'absolute', background: rgba }} />
                </div>
            }
            {obj.obj.split === "Display & 2 Cameras" &&
                <div name="Split 3 - Monitor & 2 Cam" className={`w-[1920px] h-[1080px] ${obj.obj.preview ? `` : `hidden`}`}>
                    <div style={{
                        width: 1280, height: 720,
                        right: 50, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 516, height: 290.25,
                        left: 50, top: 480,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 516, height: 290.25,
                        left: 50, top: 165,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{ width: 1920, height: 50, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 590, height: 165, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 1870, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 1920, height: 310, left: 0, top: 770, position: 'absolute', background: rgba }} />
                </div>}
            {obj.obj.split === "2 Cameras" &&
                <div name="Split 2 Cams" className={`w-[1920px] h-[1080px] ${obj.obj.preview ? `` : `hidden`}`}>
                    <div style={{
                        width: 898, height: 720, left: 972, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 898, height: 720, left: 50, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{ width: 1920, height: 50, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 1920, height: 310, left: 0, top: 770, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 1871, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 0, top: 0, position: 'absolute', background: rgba }} />

                </div>}
            {obj.obj.split === "3 Cameras" &&
                <div name="Split 3 Cams" className={`w-[1920px] h-[1080px] ${obj.obj.preview ? `` : `hidden`}`}>
                    <div style={{
                        width: 590, height: 720, left: 50, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 590, height: 720, left: 1281, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 590, height: 720, left: 666, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{ width: 1920, height: 50, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 1920, height: 310, left: 0, top: 770, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 1871, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 0, top: 0, position: 'absolute', background: rgba }} />
                </div>}
            {obj.obj.split === "4 Cameras" &&
                <div name="Split 4 Cams" className={`w-[1920px] h-[1080px] ${obj.obj.preview ? `` : `hidden`}`}>
                    <div style={{
                        width: 437, height: 720, left: 50, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 437, height: 720, left: 1434, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 437, height: 720, left: 973, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{
                        width: 437, height: 720, left: 512, top: 50,
                        position: 'absolute',
                        borderRadius: `${obj.obj.rounded}px`,
                        outline: `24px solid ${rgba}`
                    }} />
                    <div style={{ width: 1920, height: 50, left: 0, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 1920, height: 310, left: 0, top: 770, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 1871, top: 0, position: 'absolute', background: rgba }} />
                    <div style={{ width: 50, height: 1080, left: 0, top: 0, position: 'absolute', background: rgba }} />

                </div>}

        </>
    )
}

export default Splits