import React from 'react'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'


const Stripe = (obj) => {

    const isInitialMount = useRef(true);

    const previewDiv = document.getElementById('mainStripDiv');
    const [preview, setPreview] = useState(obj.obj.preview);
    const [previewInitial, setPreviewInitial] = useState(obj.obj.preview)
    const [headlineString, setHeadlineString] = useState(obj.obj.headline);
    const [sublineString, setSublineString] = useState(obj.obj.subline);
    const [imgString, setImgString] = useState(obj.obj.img)
    const [rgba, setRgba] = useState(`rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`)
    const [brdrCol, setBrdrCol] = useState(`1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`)
    const [imgBrdrCol, setImgBrdrCol] = useState(`1px solid rgba(${obj.obj.imgbordercolor.rgb.r}, ${obj.obj.imgbordercolor.rgb.g}, ${obj.obj.imgbordercolor.rgb.b}, ${obj.obj.imgbordercolor.rgb.a})`)
    const [hlCol, setHlCol] = useState(`rgba(${obj.obj.headlinecolor.rgb.r}, ${obj.obj.headlinecolor.rgb.g}, ${obj.obj.headlinecolor.rgb.b}, ${obj.obj.headlinecolor.rgb.a})`)
    const [slCol, setSlCol] = useState(`rgba(${obj.obj.sublinecolor.rgb.r}, ${obj.obj.sublinecolor.rgb.g}, ${obj.obj.sublinecolor.rgb.b}, ${obj.obj.sublinecolor.rgb.a})`)
    const [roundeness, setRoundness] = useState(obj.obj.rounded)

    useEffect(() => {
        setPreview(obj.obj.preview)
        if (isInitialMount.current) {
        } else {
            setPreviewInitial(true)
            if (obj.obj.preview) {
                setHeadlineString(obj.obj.headline);
                setSublineString(obj.obj.subline);
                setImgString(obj.obj.img);
                setRgba(`rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`)
                setBrdrCol(`1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`)
                setImgBrdrCol(`1px solid rgba(${obj.obj.imgbordercolor.rgb.r}, ${obj.obj.imgbordercolor.rgb.g}, ${obj.obj.imgbordercolor.rgb.b}, ${obj.obj.imgbordercolor.rgb.a})`)
                setHlCol(`rgba(${obj.obj.headlinecolor.rgb.r}, ${obj.obj.headlinecolor.rgb.g}, ${obj.obj.headlinecolor.rgb.b}, ${obj.obj.headlinecolor.rgb.a})`)
                setSlCol(`rgba(${obj.obj.sublinecolor.rgb.r}, ${obj.obj.sublinecolor.rgb.g}, ${obj.obj.sublinecolor.rgb.b}, ${obj.obj.sublinecolor.rgb.a})`)
                setRoundness(obj.obj.rounded)

            }
            if (preview) {
                gsap.fromTo(previewDiv,
                    { x: preview ? '0%' : '200%', },
                    { x: preview ? '200%' : '0%', duration: 1.5, ease: 'power2.inOut' }
                );

                gsap.to(document.getElementById("stripeImageContainingDiv"),
                    { width: imgString.length === 0 ? `0px` : `212px`, 
                    height: imgString.length === 0 ? `0px` : `120px`, 
                    duration: 1, ease: 'power4.inOut' }
                );

                gsap.to(document.getElementById("mainStripDiv"),
                    { gap: previewInitial && imgString.length === 0 ? `0px` : `12px`,
                    duration: 1, ease: 'power4.inOut' }
                );


            } else {
                gsap.fromTo(previewDiv,
                    { x: preview ? '0%' : '-200%', },
                    { x: preview ? '-200%' : '0%', duration: 1.5, ease: 'power2.inOut' }
                );
            }
        }
    }, [obj.obj.preview])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setRgba(`rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`)
        }
    }, [obj.obj.bgcolor])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setBrdrCol(`1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`)
        }
    }, [obj.obj.bordercolor])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setImgBrdrCol(`1px solid rgba(${obj.obj.imgbordercolor.rgb.r}, ${obj.obj.imgbordercolor.rgb.g}, ${obj.obj.imgbordercolor.rgb.b}, ${obj.obj.imgbordercolor.rgb.a})`)
        }
    }, [obj.obj.imgbordercolor])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setHlCol(`rgba(${obj.obj.headlinecolor.rgb.r}, ${obj.obj.headlinecolor.rgb.g}, ${obj.obj.headlinecolor.rgb.b}, ${obj.obj.headlinecolor.rgb.a})`)
        }
    }, [obj.obj.headlinecolor])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setSlCol(`rgba(${obj.obj.sublinecolor.rgb.r}, ${obj.obj.sublinecolor.rgb.g}, ${obj.obj.sublinecolor.rgb.b}, ${obj.obj.sublinecolor.rgb.a})`)
        }
    }, [obj.obj.sublinecolor])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setRoundness(obj.obj.rounded)
        }
    }, [obj.obj.rounded])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setHeadlineString(obj.obj.headline);
            gsap.fromTo(document.getElementById("headlineStringDiv"),
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.headline])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {

            if (obj.obj.img.length > 0) {
                setImgString(obj.obj.img);
                gsap.fromTo(document.getElementById("stripeImgDiv"),
                    { scale: 1.1 },
                    { scale: 1, duration: 1, ease: 'power4.inOut' }
                );

                gsap.to(document.getElementById("stripeImageContainingDiv"),
                    { width: `212px`, height: `120px`, duration: 1, ease: 'power4.inOut' }
                );

                gsap.to(document.getElementById("mainStripDiv"),
                    { gap: `12px`, duration: 1, ease: 'power4.inOut' }
                );
            }
            if (obj.obj.img.length === 0) {
                gsap.to(document.getElementById("stripeImageContainingDiv"),
                    { width: `0px`, height: `0px`, duration: 1, ease: 'power4.inOut' }
                );
                gsap.to(document.getElementById("mainStripDiv"),
                    { gap: `0px`, duration: 1, ease: 'power4.inOut' }
                );

            }
        }
    }, [obj.obj.img])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
            isInitialMount.current = false;
        } else {
            setSublineString(obj.obj.subline);
            gsap.fromTo(document.getElementById("sublineStringDiv"),
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.subline])

    return (
        <div id="mainStripDiv" className={`${previewInitial ? `` : `hidden`} absolute justify-start gap-3 inline-flex`}
            style={{
                position: `absolute`,
                left: `50px`, top: `852px`,
                width: `1820px`,
                height: `120px`,
                gap: previewInitial && imgString.length === 0 ? `0px` : `12px`
            }}>
            <div id="stripeImageContainingDiv" className={`justify-start items-center gap-2.5 flex overflow-clip`}
                style={{
                    height: previewInitial && imgString.length === 0 ? `0px` : `120px`,
                    width: previewInitial && imgString.length === 0 ? `0px` : `212px`,
                    border: imgBrdrCol,
                    borderRadius: `${roundeness}px`
                }}>
                <img id="stripeImgDiv" src={imgString}
                    style={{
                        objectFit: `cover`
                    }} />
            </div>
            <div className={`${obj.obj.fullWidth ? `w-full` : ``} ${sublineString.length !== 0 ? `p-2 px-4` : `p-2 px-6`} 
                ${sublineString.length === 0 && headlineString.length === 0 ? `hidden` : ``} flex-col justify-center items-center inline-flex border`}
                style={{
                    height: `120px`,
                    backgroundColor: rgba,
                    border: brdrCol,
                    borderRadius: `${roundeness}px`
                }}>
                {headlineString &&
                    <div id="headlineStringDiv"
                        className={`self-stretch ${sublineString.length !== 0 ? `text-6xl` : `text-7xl`} font-black`}
                        style={{
                            color: hlCol
                        }}>
                        {headlineString}
                    </div>}
                {sublineString &&
                    <div id="sublineStringDiv"
                        className={`${sublineString.length !== 0 ? `` : `hidden`} self-stretch grow shrink basis-0 text-4xl font-black`}
                        style={{
                            color: slCol
                        }}>
                        {sublineString}
                    </div>}

            </div>
        </div>
    )
}

export default Stripe