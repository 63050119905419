import React from 'react'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'


const LiveLabel = (obj) => {

    const isInitialMount = useRef(true);

    const previewDiv = document.getElementById('liveLabelDiv');
    const [preview, setPreview] = useState(obj.obj.preview);
    const [previewInitial, setPreviewInitial] = useState(obj.obj.preview);
    const [text, setText] = useState(obj.obj.text.text)
    const [pos, setPos] = useState([obj.obj.xpos, obj.obj.ypos])


    useEffect(() => {
        setPreview(obj.obj.preview)
        if (isInitialMount.current) {
        } else {

            if (obj.obj.preview) {
                setText(obj.obj.text.text)
                setPos([obj.obj.xpos, obj.obj.ypos])
            }
            setPreviewInitial(true)
            gsap.fromTo(previewDiv,
                { x: preview ? '0px' : '1920px', },
                { x: preview ? '1920px' : '0px', duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.preview])

    useEffect(() => {
        if (isInitialMount.current || !obj.obj.preview) {
        } else {
            setPos([obj.obj.xpos, obj.obj.ypos])
        }
    }, [obj.obj.xpos, obj.obj.ypos])

    useEffect(() => {

        if (isInitialMount.current || !obj.obj.preview) {
            isInitialMount.current = false;
        } else {
            setText(obj.obj.text.text)
            gsap.fromTo(document.getElementById('liveLabelTextDiv'),
                { opacity: 0, },
                { opacity: 100, duration: 2, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.text.text])

    useEffect(() => {
        gsap.fromTo(document.getElementById('liveLabelIconDiv'),
            { opacity: "20%", },
            { opacity: "100%", yoyo: true, repeat: -1, duration: .7 }
        );
    }, [])

    return (
        <div id="liveLabelDiv" className={`${previewInitial ? `` : `hidden`}
            h-14 px-5 absolute
            justify-end items-center gap-2.5 inline-flex`}
            style={{
                backgroundColor: `rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`,
                border: `1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`,
                borderRadius: `${obj.obj.rounded}px`,
                position: `absolute`,
                right: `${pos[0]}px`, top: `${pos[1]}px`
            }}>
            {obj.obj.icon.code.length !== 0 &&
                <div className={`w-5 h-5 text-center text-xl items-center inline-flex`}
                    style={{
                        color: `rgba(${obj.obj.icon.color.rgb.r}, ${obj.obj.icon.color.rgb.g}, ${obj.obj.icon.color.rgb.b}, ${obj.obj.icon.color.rgb.a})`
                    }}>
                    <i id="liveLabelIconDiv" className={`fa-solid fa-${obj.obj.icon.code}`}></i>
                </div>}
            {text &&
                <div id="liveLabelTextDiv" className={`text-right text-xl`}
                    style={{
                        color: `rgba(${obj.obj.text.color.rgb.r}, ${obj.obj.text.color.rgb.g}, ${obj.obj.text.color.rgb.b}, ${obj.obj.text.color.rgb.a})`,
                        fontWeight: `bold`
                    }}>
                    {text}
                </div>
            }
        </div>
    )
}

export default LiveLabel