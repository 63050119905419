import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRef } from 'react';
import Navbar from "../Navbar";
import { ChromePicker } from 'react-color'

const T1EditorV1 = () => {

  const [color, setColor] = useState({ hex: '#FF0000', rgb: { r: 255, g: 0, b: 0, a: 1 } }); // Default color with opacity

  const handleColorChange = (newColor) => {
    setColor(newColor);
  };

  const { id } = useParams();
  const isInitialMount = useRef(false);

  const [updateDBdoc, setUpdateDBdoc] = useState(false)
  const [screenData, setScreenData] = useState(null)
  const [slideSpecs, setSlideSpecs] = useState(false)
  const [stripeSpecs, setStripeSpecs] = useState(false)
  const [splitSpecs, setSplitSpecs] = useState(false)
  const [tickerSpecs, setTickerSpecs] = useState(false)
  const [personOneSpecs, setPersonOneSpecs] = useState(false)
  const [personTwoSpecs, setPersonTwoSpecs] = useState(false)
  const [labelSpecs, setLabelSpecs] = useState(false)
  const [tweetSpecs, setTweetSpecs] = useState(false)
  const [liveActions, setLiveActions] = useState(true)
  const [colorPickerName, setColorPickerName] = useState("")
  const [showAdvColorPicker, setShowAdvColorPicker] = useState(false)
  const [collapseSettings, setCollapseSettings] = useState(true)
  const [sceneName, setSceneName] = useState("")
  const [showSceneNamer, setShowSceneNamer] = useState(false)
  const [screenScenes, setScreenScenes] = useState()
  const [selectedScene, setSelectedScene] = useState("Running Scene...")
  const [colSch, setColSch] = useState(false)
  const [colSchInput, setColSchInput] = useState("#7e22ce")
  const [colSchArr, setColSchArr] = useState()
  const [showDesign, setShowDesign] = useState(true)


  const saveScene = async () => {
    fetch(`/api/scr/scene?id=${id}&name=${sceneName}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(screenData)
    })
      .then(async response => {
        const incomingScenes = await response.json()
        return setScreenScenes(incomingScenes)
      })
      .catch(error => {
        return console.log(error.message)
      });
  };

  const handleAdvColors = () => {
    setShowAdvColorPicker(false);

    const keys = colorPickerName.split('.');
    const updatedScreenData = { ...screenData };
    let target = updatedScreenData;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        // Last key in the chain
        target[key] = color;
      } else {
        target = target[key];
      }
    });

    setScreenData(updatedScreenData);
    if (liveActions) {
      setUpdateDBdoc(true);
    }
  };

  const handleTexts = (event) => {
    const { name, value, type, checked } = event.target;
    // Handle nested properties like ticker.icon.code
    const keys = name.split('.');
    const updatedScreenData = { ...screenData };
    let target = updatedScreenData;
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        // Last key in the chain
        target[key] = type === 'checkbox' ? checked : value;
      } else {
        target = target[key];
      }
    });
    setScreenData(updatedScreenData);
  };

  const startScreen = async () => {
    await fetch(`/api/scr?id=${id}`, {
      method: "GET"
    })
      .then(async response => {
        const incomingScreen = await response.json()
        return setScreenData(incomingScreen)
      })
      .catch(error => {
        console.log(error.message)
      });
  }

  const startScenes = async () => {
    await fetch(`/api/scr/scenes?id=${id}`, {
      method: "GET"
    })
      .then(async response => {
        const incomingScenes = await response.json()
        return setScreenScenes(incomingScenes)
      })
      .catch(error => {
        console.log(error.message)
      });
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    // Handle nested properties like ticker.icon.code
    const keys = name.split('.');
    const updatedScreenData = { ...screenData };
    let target = updatedScreenData;
    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        // Last key in the chain
        if (type === 'checkbox') {
          target[key] = checked;
        } else if (type === 'select-one') {
          target[key] = value;
        } else {
          target[key] = value;
        }
      } else {
        target = target[key];
      }
    });
    setScreenData(updatedScreenData);
    if (liveActions) { setUpdateDBdoc(true) }
  };

  const putScene = async (e) => {
    fetch(`/api/scr/scene?id=${id}&scene=${e.target.value}`, {
      method: "PUT"
    })
      .then(async response => {
        const incomingScreen = await response.json()
        setScreenData(incomingScreen)
        if (liveActions) { setUpdateDBdoc(true) }
      })
      .catch(error => {
        console.log(error.message)
      });
  }

  const applyPalette = async () => {
    setColSch(false)
    fetch(`/api/scr/color?id=${id}&hex=${colSchInput.replace('#', '')}`, {
      method: "POST"
    })
      .then(async response => {
        const incomingScreen = await response.json()
        setScreenData(incomingScreen)
        if (liveActions) { setUpdateDBdoc(true) }
      })
      .catch(error => {
        console.log(error.message)
      });
  }

  const deleteScene = async (sceneID) => {
    fetch(`/api/scr/scene?id=${id}&scene=${sceneID}`, {
      method: "DELETE"
    })
      .then(async response => {
        const incomingScenes = await response.json()
        setScreenScenes([])
        return setScreenScenes(incomingScenes)
      })
      .catch(error => {
        console.log(error.message)
      });
  }

  const updateScreen = async () => {
    fetch(`/api/scr?id=${id}`, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(screenData)
    })
      .then(async response => {
        const incomingScreen = await response.json()
        return setScreenData(incomingScreen)
      })
      .catch(error => {
        return console.log(error.message)
      });
  }

  const genColScheme = async () => {
    fetch(`/api/scr/color?hex=${colSchInput.replace('#', '')}`, {
      method: "GET"
    })
      .then(async response => {
        const incomingColSchArr = await response.json()
        setColSchArr(incomingColSchArr)
      })
      .catch(error => {
        console.log(error.message)
      });
  }

  useEffect(() => {
    if (isHexColor(colSchInput)) { genColScheme() }
  }, [colSchInput])

  useEffect(() => {
    setSlideSpecs(!collapseSettings)
    setStripeSpecs(!collapseSettings)
    setSplitSpecs(!collapseSettings)
    setTickerSpecs(!collapseSettings)
    setPersonOneSpecs(!collapseSettings)
    setPersonTwoSpecs(!collapseSettings)
    setLabelSpecs(!collapseSettings)
    setTweetSpecs(!collapseSettings)
  }, [collapseSettings])

  useEffect(() => {
    startScreen();
  }, [id])

  useEffect(() => {
    startScenes();
  }, [id])

  useEffect(() => {

    if (isInitialMount.current) {
      isInitialMount.current = true;
    } else {
      updateScreen();
      setUpdateDBdoc(false)
      setSelectedScene("Running Scene...")
    }

  }, [updateDBdoc, liveActions, id])

  return (<>
    <Navbar />
    {screenData &&
      <div name="MainFrame" className="w-full min-h-screen py-2.5 bg-neutral-950 flex-col justify-start items-center gap-2.5 inline-flex font-['Poppins']">
        <div className="w-full p-2 2xl:w-6/12 xl:w-6/12 lg:w-4/5 md:w-4/5 sm:w-11/12 xs:w-11/12 2xl:p-0 xl:p-0 lg:p-0 md:p-0 sm:p-0 xs:p-0 flex-col justify-start gap-2 flex">
          <div name="Control" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-neutral-900 items-center flex-col">
            <div className="text-neutral-50 text-3xl m-0 p-0 ">{screenData.name}
              <div className="text-neutral-500 text-xs p-0 mt-1"><i className={`fa-solid fa-wrench pr-2`}></i>{id}</div>
            </div>
            <div name="Controller" className="flex items-center gap-2 pt-2">
              <button
                className={`h-10 w-10 rounded-lg border border-neutral-800 bg-neutral-800 justify-center items-center`}
                onClick={(e) => {
                  setCollapseSettings(!collapseSettings)
                }}>
                <i className={`fa-solid ${collapseSettings ? `fa-circle-chevron-right` : `fa-circle-chevron-down`} text-neutral-50 text-lg`}></i>
              </button>

              <div className={`rounded-lg bg-neutral-800 justify-start items-center h-10`}>
                <div className="self-stretch px-3 h-full justify-between items-center gap-2.5 inline-flex">
                  <label className="inline-flex relative items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer"
                      checked={liveActions}
                      onChange={(e) => {
                        setLiveActions(!liveActions)
                      }} />
                    <div
                      className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-red-700">
                    </div>
                  </label>
                  <div className={`text-sm text-neutral-50`}>
                    {`${!liveActions ? `Paused` : `Action`}`}
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={(e) => { openInNewTab(`https://stream-kit.com/api/scr/png?id=${id}`) }}
                className="rounded-lg bg-neutral-800 px-3 leading-normal text-neutral-50 h-10 items-center inline-flex">
                <i className={`fa-solid fa-file-image text-neutral-50 text-lg`}></i>
                <div className='hidden sm:block ml-2 text-sm'>PNG</div>
              </button>
              <button
                onClick={(e) => { openInNewTab(`https://stream-kit.com/scr/${id}`) }}
                className={`rounded-lg bg-neutral-800 px-3 leading-normal text-neutral-50 h-10 items-center inline-flex`}>
                <i className={`fa-solid fa-tv text-neutral-50 text-base`}></i>
                <div className='hidden sm:block ml-2 text-sm'>Preview</div>
              </button>

            </div>
            <div name="Controller" className="flex items-center gap-2 pt-2">
              <button
                onClick={(e) => {
                  setShowSceneNamer(true);
                  setSelectedScene("Running Scene...")
                }}
                className={`rounded-lg bg-neutral-800 px-3 leading-normal text-neutral-50 h-10 items-center inline-flex`}>
                <i className={`fa-solid fa-floppy-disk text-neutral-50 text-base`}></i>
                <div className='hidden sm:block ml-2 text-sm'>Scenes</div>
              </button>
              {screenScenes && <select value={selectedScene} type="select-one"
                onChange={(e) => {
                  putScene(e)
                  setSelectedScene(e.target.value)
                }}
                className="grow shrink basis-0 py-2 p-2.5 border border-neutral-700 bg-neutral-900 rounded-[10px] 
              flex-col justify-center inline-flex text-right text-neutral-300 text-1 text-smase w-full focus:outline-none">
                <option value="Running Scene..." >Running Scene...</option>
                {screenScenes && screenScenes.map((sceneOption, index) => (
                  <option key={index} value={sceneOption.sceneID}>{sceneOption.name}</option>)
                )}
              </select>}
            </div>
            <div name="Controller" className="flex items-center gap-2 pt-2">
              <button
                onClick={(e) => {
                  setColSch(true);
                }}
                className={`rounded-lg bg-neutral-800 px-3 leading-normal text-neutral-50 h-10 items-center inline-flex`}>
                <i className={`fa-solid fa-swatchbook text-neutral-50 text-base`}></i>
                <div className='hidden sm:block ml-2 text-sm'>Colors</div>
              </button>
              <div className="rounded-lg bg-neutral-800 px-3 leading-normal text-neutral-50 h-10 items-center inline-flex">
                <i className={`fa-solid fa-font text-neutral-50 text-base mr-3`}></i>
                <div className="text-sm font-base mr-3">Font</div>
                <input type="text"
                  name="font"
                  value={screenData.font}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
            </div>
          </div>
          <div name="Grad" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 justify-between items-center gap-2.5 inline-flex">
            <div className="justify-center items-center gap-2.5 flex">
              <label className=" inline-flex relative items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer"
                  name="grad.preview"
                  checked={screenData.grad.preview}
                  onChange={handleChange} />
                <div
                  className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                </div>
              </label>
              <button disabled>
                <i className={`fa-solid fa-circle-chevron-right text-neutral-800`}></i></button>
              <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                style={{ backgroundColor: `rgba(${screenData.grad.color.rgb.r}, ${screenData.grad.color.rgb.g}, ${screenData.grad.color.rgb.b}, ${screenData.grad.color.rgb.a})` }}
                onClick={(e) => {
                  setColorPickerName("grad.color")
                  setColor(screenData.grad.color)
                  setShowAdvColorPicker(true)
                }} />
              <div className="text-neutral-200 text-lg font-base">Gradient</div>
            </div>
          </div>
          <div name="Splits" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer"
                    name="splits.preview"
                    checked={screenData.splits.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${splitSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setSplitSpecs(!splitSpecs)
                    }}></i></button>
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.splits.bgcolor.rgb.r}, ${screenData.splits.bgcolor.rgb.g}, ${screenData.splits.bgcolor.rgb.b}, ${screenData.splits.bgcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("splits.bgcolor")
                    setColor(screenData.splits.bgcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <button className={`hidden sm:block border border-stone-700 w-3 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.splits.bordercolor.rgb.r}, ${screenData.splits.bordercolor.rgb.g}, ${screenData.splits.bordercolor.rgb.b}, ${screenData.splits.bordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("splits.bordercolor")
                    setColor(screenData.splits.bordercolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setSplitSpecs(!splitSpecs)
                  }}>Splits</div>
              </div>
            </div>
            {splitSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <select
                type="select-one"
                name="splits.split"
                onChange={handleChange}
                className="grow shrink basis-0 py-1 p-2.5 border border-neutral-700 bg-neutral-900 rounded-[10px] flex-col justify-center inline-flex text-right text-neutral-300 text-1 text-smase w-full">
                <option selected={"Display & Camera" === screenData.splits.split} value="Display & Camera">Display & Camera</option>
                <option selected={"Display & 2 Cameras" === screenData.splits.split} value="Display & 2 Cameras">Display & 2 Cameras</option>
                <option selected={"2 Cameras" === screenData.splits.split} value="2 Cameras">2 Cameras</option>
                <option selected={"3 Cameras" === screenData.splits.split} value="3 Cameras">3 Cameras</option>
                <option selected={"4 Cameras" === screenData.splits.split} value="4 Cameras">4 Cameras</option>
              </select>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <div className="text-purple-400 text-sm font-base">{screenData.splits.rounded}px</div>
                <input type="range"
                  min="0" max="24" value={screenData.splits.rounded}
                  name="splits.rounded"
                  onChange={handleTexts}
                  onMouseUp={handleChange}
                  className="w-full appearance-none h-1 rounded-lg bg-neutral-300 accent-purple-700 accent-bold" />
              </div>
            </div>}
          </div>
          <div name="Waiting Screen" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer"
                    name="slide.preview"
                    checked={screenData.slide.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${slideSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setSlideSpecs(!slideSpecs)
                    }}></i></button>
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.slide.bgcolor.rgb.r}, ${screenData.slide.bgcolor.rgb.g}, ${screenData.slide.bgcolor.rgb.b}, ${screenData.slide.bgcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("slide.bgcolor")
                    setColor(screenData.slide.bgcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setSlideSpecs(!slideSpecs)
                  }}>Slide</div>
              </div>
            </div>
            {slideSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-3 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.slide.bordercolor.rgb.r}, ${screenData.slide.bordercolor.rgb.g}, ${screenData.slide.bordercolor.rgb.b}, ${screenData.slide.bordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("slide.bordercolor")
                    setColor(screenData.slide.bordercolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Image URL</div>
                <input type="text"
                  name="slide.img"
                  value={screenData.slide.img}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
             
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <div className="text-purple-400 text-sm font-base">{screenData.slide.rounded}px</div>
                <input type="range"
                  min="0" max="24" value={screenData.slide.rounded}
                  name="slide.rounded"
                  onChange={handleTexts}
                  onMouseUp={handleChange}
                  className="w-full appearance-none h-1 rounded-lg bg-neutral-300 accent-purple-700 accent-bold" />
              </div>
            </div>}
          </div>
          <div name="Tweet" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer"
                    name="tweet.preview"
                    checked={screenData.tweet.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${tweetSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setTweetSpecs(!tweetSpecs)
                    }}></i></button>
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.tweet.colorl.rgb.r}, ${screenData.tweet.colorl.rgb.g}, ${screenData.tweet.colorl.rgb.b}, ${screenData.tweet.colorl.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("tweet.colorl")
                    setColor(screenData.tweet.colorl)
                    setShowAdvColorPicker(true)
                  }} />
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.tweet.colorr.rgb.r}, ${screenData.tweet.colorr.rgb.g}, ${screenData.tweet.colorr.rgb.b}, ${screenData.tweet.colorr.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("tweet.colorr")
                    setColor(screenData.tweet.colorr)
                    setShowAdvColorPicker(true)
                  }} />
                {/* <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 bg-${screenData.tweet.colorr} rounded`}
                  onClick={(e) => {
                    setColorPickerName("tweet.colorr")
                    setShowColorPicker(true)
                  }} /> */}
                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setTweetSpecs(!tweetSpecs)
                  }}>Social Media Post</div>
              </div>
            </div>
            {tweetSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.tweet.name.color.rgb.r}, ${screenData.tweet.name.color.rgb.g}, ${screenData.tweet.name.color.rgb.b}, ${screenData.tweet.name.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("tweet.name.color")
                    setColor(screenData.tweet.name.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Name</div>
                <input type="text"
                  name="tweet.name.text"
                  value={screenData.tweet.name.text}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-full grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.tweet.username.color.rgb.r}, ${screenData.tweet.username.color.rgb.g}, ${screenData.tweet.username.color.rgb.b}, ${screenData.tweet.username.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("tweet.username.color")
                    setColor(screenData.tweet.username.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Username</div>
                <input type="text"
                  name="tweet.username.text"
                  value={screenData.tweet.username.text}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.tweet.date.color.rgb.r}, ${screenData.tweet.date.color.rgb.g}, ${screenData.tweet.date.color.rgb.b}, ${screenData.tweet.date.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("tweet.date.color")
                    setColor(screenData.tweet.date.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Date</div>
                <input type="text"
                  name="tweet.date.text"
                  value={screenData.tweet.date.text}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.tweet.text.color.rgb.r}, ${screenData.tweet.text.color.rgb.g}, ${screenData.tweet.text.color.rgb.b}, ${screenData.tweet.text.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("tweet.text.color")
                    setColor(screenData.tweet.text.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Text</div>
                <textarea rows={5} type="text"
                  name="tweet.text.text"
                  value={screenData.tweet.text.text}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Image URL</div>
                <input type="text"
                  name="tweet.img"
                  value={screenData.tweet.img}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
            </div>}
          </div>
          <div name="Main Titles" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer"
                    name="stripe.preview"
                    checked={screenData.stripe.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${stripeSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setStripeSpecs(!stripeSpecs)
                    }}></i></button>
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.stripe.bgcolor.rgb.r}, ${screenData.stripe.bgcolor.rgb.g}, ${screenData.stripe.bgcolor.rgb.b}, ${screenData.stripe.bgcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("stripe.bgcolor")
                    setColor(screenData.stripe.bgcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <button className={`hidden sm:block border border-stone-700 w-3 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.stripe.bordercolor.rgb.r}, ${screenData.stripe.bordercolor.rgb.g}, ${screenData.stripe.bordercolor.rgb.b}, ${screenData.stripe.bordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("stripe.bordercolor")
                    setColor(screenData.stripe.bordercolor)
                    setShowAdvColorPicker(true)
                  }} />

                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setStripeSpecs(!stripeSpecs)
                  }}>Headlines</div>
              </div>
            </div>
            {stripeSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.stripe.headlinecolor.rgb.r}, ${screenData.stripe.headlinecolor.rgb.g}, ${screenData.stripe.headlinecolor.rgb.b}, ${screenData.stripe.headlinecolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("stripe.headlinecolor")
                    setColor(screenData.stripe.headlinecolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Headline</div>
                <input type="text"
                  name="stripe.headline"
                  value={screenData.stripe.headline}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
                {/* checkbox here! */}
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer"
                    name="stripe.fullWidth"
                    checked={screenData.stripe.fullWidth}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-4 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.stripe.sublinecolor.rgb.r}, ${screenData.stripe.sublinecolor.rgb.g}, ${screenData.stripe.sublinecolor.rgb.b}, ${screenData.stripe.sublinecolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("stripe.sublinecolor")
                    setColor(screenData.stripe.sublinecolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Subline</div>
                <input type="text"
                  name="stripe.subline"
                  value={screenData.stripe.subline}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-2 h-5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.stripe.imgbordercolor.rgb.r}, ${screenData.stripe.imgbordercolor.rgb.g}, ${screenData.stripe.imgbordercolor.rgb.b}, ${screenData.stripe.imgbordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("stripe.imgbordercolor")
                    setColor(screenData.stripe.imgbordercolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Image URL</div>
                <input type="text"
                  name="stripe.img"
                  value={screenData.stripe.img}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <div className="text-purple-400 text-sm font-base">{screenData.stripe.rounded}px</div>
                <input type="range"
                  min="0" max="24" value={screenData.stripe.rounded}
                  name="stripe.rounded"
                  onChange={handleTexts}
                  onMouseUp={handleChange}
                  className="w-full appearance-none h-1 rounded-lg bg-neutral-300 accent-purple-700 accent-bold" />
              </div>
            </div>}
          </div>
          <div name="Ticker" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="ticker.preview"
                    checked={screenData.ticker.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${tickerSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setTickerSpecs(!tickerSpecs)
                    }}></i></button>
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.ticker.bgcolor.rgb.r}, ${screenData.ticker.bgcolor.rgb.g}, ${screenData.ticker.bgcolor.rgb.b}, ${screenData.ticker.bgcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("ticker.bgcolor")
                    setColor(screenData.ticker.bgcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <button className={`hidden sm:block border border-stone-700 w-3 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.ticker.bordercolor.rgb.r}, ${screenData.ticker.bordercolor.rgb.g}, ${screenData.ticker.bordercolor.rgb.b}, ${screenData.ticker.bordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("ticker.bordercolor")
                    setColor(screenData.ticker.bordercolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setTickerSpecs(!tickerSpecs)
                  }}>Ticker</div>
              </div>

            </div>
            {tickerSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">

                <div className="text-neutral-300 text-sm font-base">RSS</div>
                <input type="text"
                  name="ticker.rss.url"
                  value={screenData.ticker.rss.url}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="ticker.rss.preview"
                    checked={screenData.ticker.rss.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-4 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.ticker.text.color.rgb.r}, ${screenData.ticker.text.color.rgb.g}, ${screenData.ticker.text.color.rgb.b}, ${screenData.ticker.text.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("ticker.text.color")
                    setColor(screenData.ticker.text.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Main Text</div>
                <input type="text"
                  name="ticker.text.value"
                  value={screenData.ticker.text.value}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.ticker.icon.color.rgb.r}, ${screenData.ticker.icon.color.rgb.g}, ${screenData.ticker.icon.color.rgb.b}, ${screenData.ticker.icon.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("ticker.icon.color")
                    setColor(screenData.ticker.icon.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Icon</div>
                <input type="text"
                  name="ticker.icon.awCode"
                  value={screenData.ticker.icon.awCode}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.ticker.cta.color.rgb.r}, ${screenData.ticker.cta.color.rgb.g}, ${screenData.ticker.cta.color.rgb.b}, ${screenData.ticker.cta.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("ticker.cta.color")
                    setColor(screenData.ticker.cta.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">CTA</div>
                <input type="text"
                  name="ticker.cta.value"
                  value={screenData.ticker.cta.value}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <div className="text-purple-400 text-sm font-base">{screenData.ticker.rounded}px</div>
                <input type="range"
                  min="0" max="24" value={screenData.ticker.rounded}
                  name="ticker.rounded"
                  onChange={handleTexts}
                  onMouseUp={handleChange}
                  className="w-full appearance-none h-1 rounded-lg bg-neutral-300 accent-purple-700 accent-bold" />
              </div>
              <div className="hidden sm:flex self-stretch h-6 justify-between items-center inline-flex">
                <div className="justify-center items-center gap-2.5 flex">
                  <div className="text-neutral-300 text-sm font-base">Facebook</div>
                </div>
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="ticker.soMe.fb"
                    checked={screenData.ticker.soMe.fb}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-4 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
              </div>
              <div className="hidden sm:flex self-stretch h-6 justify-between items-center inline-flex">
                <div className="justify-center items-center gap-2.5 flex">
                  <div className="text-neutral-300 text-sm font-base">Instagram</div>
                </div>
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="ticker.soMe.ig"
                    checked={screenData.ticker.soMe.ig}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-4 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
              </div>
              <div className="hidden sm:flex self-stretch h-6 justify-between items-center inline-flex">
                <div className="justify-center items-center gap-2.5 flex">
                  <div className="text-neutral-300 text-sm font-base">Twitter</div>
                </div>
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="ticker.soMe.x"
                    checked={screenData.ticker.soMe.x}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-4 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
              </div>
              <div className="hidden sm:flex self-stretch h-6 justify-between items-center inline-flex">
                <div className="justify-center items-center gap-2.5 flex">
                  <div className="text-neutral-300 text-sm font-base">Youtube</div>
                </div>
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="ticker.soMe.yt"
                    checked={screenData.ticker.soMe.yt}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-4 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-3 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
              </div>
            </div>}
          </div>
          <div name="PersonOne" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="personOne.preview"
                    checked={screenData.personOne.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${personOneSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setPersonOneSpecs(!personOneSpecs)
                    }}></i></button>
                <button
                  className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personOne.bgcolor.rgb.r}, ${screenData.personOne.bgcolor.rgb.g}, ${screenData.personOne.bgcolor.rgb.b}, ${screenData.personOne.bgcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personOne.bgcolor")
                    setColor(screenData.personOne.bgcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <button className={`hidden sm:block border border-stone-700 w-3 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personOne.bordercolor.rgb.r}, ${screenData.personOne.bordercolor.rgb.g}, ${screenData.personOne.bordercolor.rgb.b}, ${screenData.personOne.bordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personOne.bordercolor")
                    setColor(screenData.personOne.bordercolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setPersonOneSpecs(!personOneSpecs)
                  }}>Lower Third</div><p className="text-neutral-500 text-lg font-black">I</p>
              </div>
            </div>
            {personOneSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personOne.iconcolor.rgb.r}, ${screenData.personOne.iconcolor.rgb.g}, ${screenData.personOne.iconcolor.rgb.b}, ${screenData.personOne.iconcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personOne.iconcolor")
                    setColor(screenData.personOne.iconcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Icon</div>
                <input type="text"
                  name="personOne.icon"
                  value={screenData.personOne.icon}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personOne.namecolor.rgb.r}, ${screenData.personOne.namecolor.rgb.g}, ${screenData.personOne.namecolor.rgb.b}, ${screenData.personOne.namecolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personOne.namecolor")
                    setColor(screenData.personOne.namecolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Name</div>
                <input type="text"
                  name="personOne.name"
                  value={screenData.personOne.name}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personOne.functioncolor.rgb.r}, ${screenData.personOne.functioncolor.rgb.g}, ${screenData.personOne.functioncolor.rgb.b}, ${screenData.personOne.functioncolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personOne.functioncolor")
                    setColor(screenData.personOne.functioncolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Function</div>
                <input type="text"
                  name="personOne.function"
                  value={screenData.personOne.function}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <div className="text-purple-400 text-sm font-base">{screenData.personOne.rounded}px</div>
                <input type="range"
                  min="0" max="24" value={screenData.personOne.rounded}
                  name="personOne.rounded"
                  onChange={handleTexts}
                  onMouseUp={handleChange}
                  className="w-full appearance-none h-1 rounded-lg bg-neutral-300 accent-purple-700 accent-bold" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Position</div>
                <input type="number"
                  name="personOne.xpos"
                  value={screenData.personOne.xpos}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-24 basis-0 py-1 text-sm font-base p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-purple-400" />
                <input type="number"
                  name="personOne.ypos"
                  value={screenData.personOne.ypos}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-24 basis-0 py-1 text-sm font-base p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-purple-400" />
              </div>
            </div>}
          </div>
          <div name="PersonTwo" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="personTwo.preview"
                    checked={screenData.personTwo.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${personTwoSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setPersonTwoSpecs(!personTwoSpecs)
                    }}></i></button>
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personTwo.bgcolor.rgb.r}, ${screenData.personTwo.bgcolor.rgb.g}, ${screenData.personTwo.bgcolor.rgb.b}, ${screenData.personTwo.bgcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personTwo.bgcolor")
                    setColor(screenData.personTwo.bgcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <button className={`hidden sm:block border border-stone-700 w-3 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personTwo.bordercolor.rgb.r}, ${screenData.personTwo.bordercolor.rgb.g}, ${screenData.personTwo.bordercolor.rgb.b}, ${screenData.personTwo.bordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personTwo.bordercolor")
                    setColor(screenData.personTwo.bordercolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setPersonTwoSpecs(!personTwoSpecs)
                  }}>Lower Third</div><p className="text-neutral-500 text-lg font-black">II</p>
              </div>
            </div>
            {personTwoSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personTwo.iconcolor.rgb.r}, ${screenData.personTwo.iconcolor.rgb.g}, ${screenData.personTwo.iconcolor.rgb.b}, ${screenData.personTwo.iconcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personTwo.iconcolor")
                    setColor(screenData.personTwo.iconcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Icon</div>
                <input type="text"
                  name="personTwo.icon"
                  value={screenData.personTwo.icon}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personTwo.namecolor.rgb.r}, ${screenData.personTwo.namecolor.rgb.g}, ${screenData.personTwo.namecolor.rgb.b}, ${screenData.personTwo.namecolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personTwo.namecolor")
                    setColor(screenData.personTwo.namecolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Name</div>
                <input type="text"
                  name="personTwo.name"
                  value={screenData.personTwo.name}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.personTwo.functioncolor.rgb.r}, ${screenData.personTwo.functioncolor.rgb.g}, ${screenData.personTwo.functioncolor.rgb.b}, ${screenData.personTwo.functioncolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("personTwo.functioncolor")
                    setColor(screenData.personTwo.functioncolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Function</div>
                <input type="text"
                  name="personTwo.function"
                  value={screenData.personTwo.function}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <div className="text-purple-400 text-sm font-base">{screenData.personTwo.rounded}px</div>
                <input type="range"
                  min="0" max="24" value={screenData.personTwo.rounded}
                  name="personTwo.rounded"
                  onChange={handleTexts}
                  onMouseUp={handleChange}
                  className="w-full appearance-none h-1 rounded-lg bg-neutral-300 accent-purple-700 accent-bold" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Position</div>
                <input type="number"
                  name="personTwo.xpos"
                  value={screenData.personTwo.xpos}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-24 basis-0 py-1 text-sm font-base p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-purple-400" />
                <input type="number"
                  name="personTwo.ypos"
                  value={screenData.personTwo.ypos}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-24 basis-0 py-1 text-sm font-base p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-purple-400" />
              </div>
            </div>}
          </div>
          <div name="Live Label" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-l from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer"
                    name="liveLabel.preview"
                    checked={screenData.liveLabel.preview}
                    onChange={handleChange} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
                <button>
                  <i className={`fa-solid fa-circle-chevron-${labelSpecs ? `down` : `right`} text-neutral-400`}
                    onClick={(e) => {
                      setLabelSpecs(!labelSpecs)
                    }}></i></button>
                <button className={`hidden sm:block border border-stone-700 w-6 h-6 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.liveLabel.bgcolor.rgb.r}, ${screenData.liveLabel.bgcolor.rgb.g}, ${screenData.liveLabel.bgcolor.rgb.b}, ${screenData.liveLabel.bgcolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("liveLabel.bgcolor")
                    setColor(screenData.liveLabel.bgcolor)
                    setShowAdvColorPicker(true)
                  }} />
                <button className={`hidden sm:block border border-stone-700 w-3 h-6 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.liveLabel.bordercolor.rgb.r}, ${screenData.liveLabel.bordercolor.rgb.g}, ${screenData.liveLabel.bordercolor.rgb.b}, ${screenData.liveLabel.bordercolor.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("liveLabel.bordercolor")
                    setColor(screenData.liveLabel.bordercolor)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-200 text-lg font-base"
                  onClick={(e) => {
                    setLabelSpecs(!labelSpecs)
                  }}>Live Label</div>
              </div>
            </div>
            {labelSpecs && <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.liveLabel.icon.color.rgb.r}, ${screenData.liveLabel.icon.color.rgb.g}, ${screenData.liveLabel.icon.color.rgb.b}, ${screenData.liveLabel.icon.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("liveLabel.icon.color")
                    setColor(screenData.liveLabel.icon.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Icon</div>
                <input type="text"
                  name="liveLabel.icon.code"
                  value={screenData.liveLabel.icon.code}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-full grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={`hidden sm:block border border-stone-700 w-5 h-5 p-2.5 rounded`}
                  style={{ backgroundColor: `rgba(${screenData.liveLabel.text.color.rgb.r}, ${screenData.liveLabel.text.color.rgb.g}, ${screenData.liveLabel.text.color.rgb.b}, ${screenData.liveLabel.text.color.rgb.a})` }}
                  onClick={(e) => {
                    setColorPickerName("liveLabel.text.color")
                    setColor(screenData.liveLabel.text.color)
                    setShowAdvColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Text</div>
                <input type="text"
                  name="liveLabel.text.text"
                  value={screenData.liveLabel.text.text}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <div className="text-purple-400 text-sm font-base">{screenData.liveLabel.rounded}px</div>
                <input type="range"
                  min="0" max="24" value={screenData.liveLabel.rounded}
                  name="liveLabel.rounded"
                  onChange={handleTexts}
                  onMouseUp={handleChange}
                  className="w-full appearance-none h-1 rounded-lg bg-neutral-300 accent-purple-700 accent-bold" />
              </div>
              <div className="hidden sm:flex self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Position</div>
                <input type="number"
                  name="liveLabel.xpos"
                  value={screenData.liveLabel.xpos}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-24 basis-0 py-1 text-sm font-base p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-purple-400" />
                <input type="number"
                  name="liveLabel.ypos"
                  value={screenData.liveLabel.ypos}
                  onChange={handleTexts}
                  onBlur={handleChange}
                  className="w-24 basis-0 py-1 text-sm font-base p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-purple-400" />
              </div>
            </div>}
          </div>

          {showAdvColorPicker &&
            <div id="small-modal" tabIndex="-1" className="fixed flex justify-center items-center w-full p-2 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black/70">
              <div className="max-w-sm max-h-full">
                <div className="rounded-lg bg-neutral-950 border border-neutral-800 overflow-clip shadow-[0_0px_100px_0px_#000000]">
                  <div className="flex items-center justify-between p-0 px-2 rounded-t bg-gradient-to-b from-neutral-900 to-neutral-950">
                    <h3 className="text-xs my-2 font-medium text-gray-50 dark:text-neutral-300">
                      Choose Color
                    </h3>
                    <button
                      onClick={(e) => {
                        setShowAdvColorPicker(false)
                        handleAdvColors();
                      }}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-800 hover:text-gray-900 rounded-lg text-sm w-4 h-4 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-800 dark:hover:text-neutral-300" data-modal-hide="small-modal">
                      <svg className="w-2 h-2 m-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <ChromePicker color={color.rgb} onChange={handleColorChange} disableAlpha={false} />
                </div>
              </div>
            </div>}

          {showSceneNamer &&
            <div id="small-modal" tabIndex="-1" className="fixed flex justify-center w-full p-2 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black/70">
              <div className="max-w-sm max-h-full">
                <div className="rounded-lg bg-neutral-950 border border-neutral-800 overflow-clip shadow-[0_0px_100px_0px_#000000] mt-36">
                  <div className="flex items-center justify-between p-0 px-2 rounded-t bg-gradient-to-b from-neutral-900 to-neutral-950">
                    <h3 className="text-xs my-2 font-medium text-neutral-50">
                      Scenes
                    </h3>
                    <button
                      onClick={(e) => {
                        setShowSceneNamer(false)
                      }}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-800 hover:text-gray-900 rounded-lg text-sm w-4 h-4 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-800 dark:hover:text-neutral-300" data-modal-hide="small-modal">
                      <svg className="w-2 h-2 m-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-2 flex-col">
                    <input type="text"
                      name="tweet.name.text"
                      value={sceneName}
                      placeholder="Enter Scene Name..."
                      onChange={(e) => { setSceneName(e.target.value) }}
                      className="w-full grow shrink basis-0 py-1 p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-50 font-normal w-full" />
                    <button
                      onClick={(e) => {
                        saveScene()
                      }}
                      className={`rounded-lg bg-neutral-800 px-3 p-1 leading-normal text-neutral-50 items-center inline-flex mt-2`}>
                      <i className={`fa-solid fa-floppy-disk text-neutral-50 text-base`}></i>
                      <div className='ml-2 text-sm'>Save current</div>
                    </button>
                    <div className="flex-col mt-2">
                      <div className='text-xs text-neutral-50 mt-4'>Saved scenes</div>
                      {screenScenes && screenScenes.map((sceneOption, index) => (<>
                        <div
                          key={index}
                          className={`rounded-lg bg-neutral-900 px-3 p-1 leading-normal text-neutral-200 items-center inline-flex mt-2`}>
                          <button onClick={(e) => {
                            deleteScene(sceneOption.sceneID)
                          }}>
                            <i className={`fa-solid fa-delete-left text-neutral-50`}
                            ></i>
                          </button>
                          <span className='ml-2'>{sceneOption.name}</span>
                        </div><br />
                      </>)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>}

          {colSch &&
            <div id="small-modal" tabIndex="-1" className="fixed flex justify-center w-full p-2 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black/70">
              <div className="max-w-sm max-h-full">
                <div className="rounded-lg bg-neutral-950 border border-neutral-800 overflow-clip shadow-[0_0px_100px_0px_#000000] mt-36">
                  <div className="flex items-center justify-between p-0 px-2 rounded-t bg-gradient-to-b from-neutral-900 to-neutral-950">
                    <h3 className="text-xs my-2 font-medium text-neutral-50">
                      Color Scheme
                    </h3>
                    <button
                      onClick={(e) => {
                        setColSch(false)
                      }}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-800 hover:text-gray-900 rounded-lg text-sm w-4 h-4 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-800 dark:hover:text-neutral-300" data-modal-hide="small-modal">
                      <svg className="w-2 h-2 m-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-2 flex-col">
                    <input type="text"
                      value={colSchInput}
                      placeholder="Hex..."
                      onChange={(e) => {
                        setColSchInput(e.target.value)
                      }}
                      className="w-full grow shrink basis-0 py-1 p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-50 font-normal w-full focus:outline-none" />
                    <button
                      onClick={(e) => {
                        applyPalette()
                      }}
                      className={`rounded-lg bg-neutral-800 px-3 p-1 leading-normal text-neutral-50 items-center inline-flex mt-2`}>
                      <i className={`fa-solid fa-floppy-disk text-neutral-50 text-base`}></i>
                      <div className='ml-2 text-sm'>Use this palette</div>
                    </button>
                    <div className="flex-col mt-2">
                      {colSchInput && isHexColor(colSchInput) &&
                        <div key={'userHexInput'}
                          className={`rounded-lg bg-neutral-900 px-2 p-1 text-neutral-50 items-center w-full inline-flex mt-2`}>
                          <i className={`fa-solid fa-square text-2xl`}
                            style={{
                              color: `#${colSchInput.replace('#', '')}`
                            }}></i>
                          <span className='ml-2 text-sm'>#{colSchInput.replace('#', '')}</span>
                        </div>}
                      <br />
                      {colSchArr && colSchArr.map((color, index) => (<>
                        <div
                          key={index}
                          className={`rounded-lg bg-neutral-900 px-1 text-neutral-50 items-center w-full inline-flex mt-1`}>
                          <i className={`fa-solid fa-square text-xl`}
                            style={{
                              color: `${color}`
                            }}></i>
                          <span className='ml-2 text-xs p-0 m-0'>{color}</span>
                        </div><br />
                      </>)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>}
        </div>
        <br />
      </div>
    }
  </>
  );
};
export default T1EditorV1;

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

function isHexColor(str) {
  // Regular expression to match a valid hex color code
  const hexColorRegex = /^#?([0-9A-Fa-f]{3}$|[0-9A-Fa-f]{6}$)/;

  // Check if the input string matches the regex pattern
  return hexColorRegex.test(str);
}
