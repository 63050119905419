
import { Routes, Route } from 'react-router-dom'
import Editor from './component/editor/Editor'
import Preview from './component/preview/Preview'
import Home from './component/home/Home';


function App() {
  return (
    <Routes>
      <Route
        path="ctr/:id"
        element={<Editor />}
      />
      <Route
        path="/scr/:id"
        element={<Preview />}
      />
      <Route
        path="/"
        element={<Home />}
      />

    </Routes>
  );
}

export default App;
