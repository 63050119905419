import React, { useState, useEffect } from "react";
import { gsap } from 'gsap'
import laptop3 from "../../img/laptop3.png"
import laptop4 from "../../img/laptop4.png"
import instruct from "../../img/instructions.png"
import display1Img from '../../img/display1.jpg'
import buildingIMG from '../../img/building.jpg'
import showIMG from '../../img/show.jpg'
import keyboardIMG from '../../img/keyboard.jpg'


import Navbar from "../Navbar";
import { Link } from "react-router-dom"

export default function Home({ }) {


  const [newScreenName, setNewScreenName] = useState()
  const [newScreenNumber, setNewScreenNumber] = useState()
  const [showSpecs, setShowSpecs] = useState(true)

  const [colorPickerName, setColorPickerName] = useState("")
  const [colorPickerSelection, setColorPickerSelection] = useState("")
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [changeColor, setChangeColor] = useState(true)

  const [bordercolor, setBordercolor] = useState("neutral-800")
  const [bgcolor, setBgcolor] = useState("neutral-900")
  const [rounded, setRounded] = useState("none")
  const [preview, setPreview] = useState(true)

  const [iconcolor, setIconcolor] = useState("red-500")
  const [personcolor, setPersoncolor] = useState("neutral-100")
  const [profcolor, setProfcolor] = useState("neutral-400")

  const [icon, setIcon] = useState("circle")
  const [person, setPerson] = useState("LIVE")
  const [personPrev, setPersonPrev] = useState(person)
  const [prof, setProf] = useState("LONDON")
  const [profPrev, setProfPrev] = useState(prof)


  const newScreen = async function () {
    fetch(`/api/scr?name=${newScreenName}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${user.token}`
      },
    })
      .then(async response => {
        console.log(response)
        const incomingScreen = await response.json()
        console.log(incomingScreen)
        setNewScreenNumber(incomingScreen.id)
      })
      .catch(error => {
        return console.log(error.message)
      });
  }

  useEffect((e) => {
    const newColor = () => {
      if (colorPickerName === "bgcolor") {
        setBgcolor(colorPickerSelection)
      }
      if (colorPickerName === "bordercolor") {
        setBordercolor(colorPickerSelection)
      }
      if (colorPickerName === "iconcolor") {
        setIconcolor(colorPickerSelection)
      }
      if (colorPickerName === "personcolor") {
        setPersoncolor(colorPickerSelection)
      }
      if (colorPickerName === "profcolor") {
        setProfcolor(colorPickerSelection)
      }
    }

    setShowColorPicker(false)

    newColor()

  }, [changeColor])

  return (
    <>
      <Navbar />
      <div className="w-full min-h-screen py-2.5 bg-gradient-to-b from-neutral-950 to-neutral-900 flex-col justify-start items-center gap-2.5 inline-flex font-['Poppins']">
        <div name="Titles" className="w-11/12 2xl:w-6/12 xl:w-6/12 lg:w-4/5 md:w-4/5 sm:w-11/12 xs:w-11/12  gap-2.5 inline-flex justify-left">
          <div className=''>
            <div id="slider" className="text-neutral-50 text-6xl pb-4 pt-16 font-semibold">
              Lighter Than Air,<br />Realtime Graphics
            </div>
            <div className="text-violet-300 py-4 text-2xl font-light">
              Overlay dynamic On-Air Motion Graphics on top of a stream, easily.
            </div>
            <br /><br />

            {!newScreenNumber &&
              <button
                type="button"
                onClick={(e) => {
                  if (showSpecs && newScreenName) { newScreen() }
                  if (!showSpecs) { setShowSpecs(!showSpecs) }
                }}
                className=" rounded-lg bg-gradient-to-b from-violet-600 to-violet-900 py-2 px-5 font-medium leading-normal text-neutral-50 shadow-[0_0px_120px_0px_#7c3aed] transition duration-150 ease-in-out hover:bg-violet-600 hover:shadow-[0_0px_80px_0px_#7c3aed] active:bg-primary-700 active:shadow-[0_8px_9px_-4px_#7c3aed,0_4px_18px_0_rgba(59,113,202,0.2)]dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)]dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]">
                <i className={`fa-solid fa-${!showSpecs ? `bars-progress` : `tv`} pr-3 text-neutral-50`}></i>
                <span>{!showSpecs ? "New Screen" : "Create Screen"}</span>
              </button>}
            {newScreenNumber && <>
              <h className=" rounded-lg bg-gradient-to-b from-violet-600 to-violet-900 mb-6 py-2 px-5 font-semibold text-xs leading-normal text-neutral-50 shadow-[0_0px_120px_0px_#7c3aed]">
                <i className="fa-solid fa-tv pr-3 text-neutral-50"></i>
                <span>{newScreenNumber}</span>
              </h><br /><br />
              <h className="text-neutral-100 text-lg font-base"> Save the number above.</h><br />
              <h className="text-neutral-100 text-lg font-base"> It won't appear anywhere again. </h><br /><br />
              <div className=" rounded-lg bg-gradient-to-b from-neutral-800 to-neutral-900 mb-4 py-2 px-5 font-medium text-xl leading-normal text-neutral-50 inline-flex">
                <i className="fa-solid fa-wrench text-xl"></i>
                <Link className=" ml-3 text-xl" to={`/ctr/${newScreenNumber}`}>
                  Controller
                </Link>
              </div><br />
              <div className=" rounded-lg bg-gradient-to-b from-neutral-800 to-neutral-900 mb-6 py-2 px-5 font-medium text-xl leading-normal text-neutral-50 inline-flex">
                <i className="fa-solid fa-display text-xl"></i>
                <Link className=" ml-3 text-xl" to={`/scr/${newScreenNumber}`}>Screen</Link>
              </div>
            </>}
            <div>
              {!newScreenNumber && showSpecs &&
                <label className="block mb-2 text-sm font-medium text-neutral-50 mt-5 ">
                  <input
                    type="text"
                    className="px-5 bg-transparent border border-violet-600 text-neutral-50 text-sm rounded-lg block p-2.5 focus:outline-none w-4/5 sm:w-5/12"
                    placeholder="Screen Name"
                    onChange={(e) => setNewScreenName(e.target.value)}
                    required />
                </label>}
            </div>
          </div>
        </div>
        <br />
        {/* <div name="Image" className="w-11/12 2xl:w-8/12 xl:w-8/12 lg:w-4/5 md:w-4/5 sm:w-11/12 xs:w-11/12 items-center gap-2.5 inline-flex justify-center">
          {!newScreenNumber && <img src={laptop3} className="m-0 " />}
          { !newScreenNumber && <img src={laptop2} className="m-0" />}
          {newScreenNumber && <img src={laptop4} className="m-0 " />}
        </div> */}
        <br />
        <div name="Exaplanation" className="w-11/12 2xl:w-6/12 xl:w-6/12 lg:w-4/5 md:w-4/5 sm:w-11/12 xs:w-11/12 gap-2.5 justify-center border border-neutral-800 p-8 rounded-lg mb-6">
          <div className="text-neutral-100 text-4xl font-semibold">
            It's that simple
          </div>
          <div className="text-neutral-100 py-6 text-lg sm:text-xl font-light">
            No authentication, no account. There's also nothing to download. <br /> All you need is a screen number.
            After getting one, you can access the controller and the screen.
          </div>

          <i className="fa-solid fa-wrench text-neutral-50 text-xl sm:text-2xl pl-2"></i>
          <h className="text-neutral-200  m-4 text-lg sm:text-xl">Controller</h><br />
          <p className="text-neutral-400  mt-4 text-sm sm:text-base">
            This is where you adjust the visuals of the screen, change texts, and take elements in and out.
            <br /> Actions taken on this page will be effective in the FullHD Screen in realtime.
            <br /> The controller is mobile firendly.
          </p>
          <br />
          <i className="fa-solid fa-eye text-neutral-50 text-xl sm:text-2xl pl-2"></i>
          <h className="text-neutral-200  m-4 text-lg sm:text-xl">Screen</h>
          <p className="text-neutral-400  mt-4 text-sm sm:text-base">
            This is a FullHD web page that goes into your broadcasting software as a browser source.
          </p>
        </div>

        {/* <div name="Image2" className={`w-11/12 2xl:w-6/12 xl:w-6/12 lg:w-4/5 md:w-4/5 sm:w-11/12 xs:w-11/12 gap-2.5 mb-6 p-8 border border-neutral-800 rounded-lg`}>
          <div className="flex gap-4">
            <div className="w-4/12 border border-neutral-800 rounded-lg overflow-clip">
              <img src={showIMG} className="object-cover" />
            </div>
            <div className="w-4/12 border border-neutral-800 rounded-lg overflow-clip">
              <img src={buildingIMG} className="object-cover" />
            </div>
            <div className="w-4/12 border border-neutral-800 rounded-lg overflow-clip">
              <img src={keyboardIMG} className="object-cover" />
            </div>
          </div>
          <br />
          <div name="Explanation">
            <div className="text-neutral-200 text-5xl font-semibold">
              Jam session, pilates class, or a team meeting
            </div>
            <div className="text-violet-500 text-lg sm:text-xl font-light align-middle items-center justify-center pt-4">
              You'll find broadcast elements that match any occasion.
            </div>
          </div>
        </div> */}



        {/* <div name="Label" className="w-11/12 2xl:w-6/12 xl:w-6/12 lg:w-4/5 md:w-4/5 sm:w-11/12 xs:w-11/12 gap-2.5 justify-center rounded-lg border border-neutral-800 overflow-clip">
          <div className="text-neutral-50 text-3xl font-semibold pb-6 p-8">
            Hold the wheel
          </div>
          <div className="text-neutral-100 px-8 text-base sm:text-lg font-light">
            Here's a label. Try adjusting the visuals and texts with the controller.
          </div>
          <div name="preview" className="overflow-clip self-stretch border border-neutral-800 p-2.5 bg-neutral-950 flex-col justify-start items-center gap-2.5 flex m-4 py-16">
            <center>
              <div className={`
                px-4 py-1 h-12
                border border-${bordercolor}
                bg-${bgcolor}
                rounded-${rounded} 
                drop-shadow-[0_0px_112px_#b91c1c]
                animate__animated
                justify-left items-center gap-3 inline-flex
                ${preview ? `animate__fadeInLeft` : `animate__fadeOutLeft`}
                `}>
                <div className={`w-5 h-5 text-center text-${iconcolor} text-lg items-center inline-flex `}>
                  <i className={`fa-solid fa-${icon} animate__animated animate__flash animate__infinite animate__slow`}></i>
                </div>
                <div className={`text-${personcolor} text-xl font-semibold`}>
                  {personPrev}
                </div>
                <div className={`text-${profcolor} text-xl font-normal`}>
                  {profPrev}
                </div>
              </div>
            </center>
          </div>
          <div name="PersonOne" className="self-stretch rounded-lg border border-neutral-800 p-2.5 bg-gradient-to-b from-neutral-900 to-neutral-950 flex-col justify-start items-center gap-2.5 flex m-4">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-center items-center gap-2.5 flex">
                <button>
                  <i className={`fa-solid fa-circle-chevron-down text-neutral-400`}></i>
                </button>
                <button className={` border border-stone-700 w-6 h-6 p-2.5 bg-${bgcolor} rounded`}
                  onClick={(e) => {
                    setColorPickerName("bgcolor")
                    setShowColorPicker(true)
                  }} />
                <button className={` border border-stone-700 w-5 h-5 p-2.5 bg-${bordercolor} rounded`}
                  onClick={(e) => {
                    setColorPickerName("bordercolor")
                    setShowColorPicker(true)
                  }} />
                <div className="text-neutral-200 text-lg font-base">
                  Label
                </div>
              </div>
              <div className="justify-center items-center gap-2.5 flex">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    name="personOne.preview"
                    checked={preview}
                    onChange={(e) => {
                      setPreview(!preview)
                    }} />
                  <div
                    className="w-11 h-6 bg-neutral-600 rounded-full peer  peer-focus:ring-red-600  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-neutral-200 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600">
                  </div>
                </label>
              </div>
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={` border border-stone-700 w-5 h-5 p-2.5 bg-${iconcolor} rounded`}
                  onClick={(e) => {
                    setColorPickerName("iconcolor")
                    setShowColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Icon</div>
                <input type="text"
                  name="personOne.icon"
                  value={icon}
                  onChange={(e) => {
                    setIcon(e.target.value)
                  }}
                  // onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={` border border-stone-700 w-5 h-5 p-2.5 bg-${personcolor} rounded`}
                  onClick={(e) => {
                    setColorPickerName("personcolor")
                    setShowColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Name</div>
                <input type="text"
                  name="personOne.name"
                  value={person}
                  onChange={(e) => {
                    setPerson(e.target.value)
                  }}
                  onBlur={(e) => {
                    setPersonPrev(e.target.value)
                  }}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <button className={` border border-stone-700 w-5 h-5 p-2.5 bg-${profcolor} rounded`}
                  onClick={(e) => {
                    setColorPickerName("profcolor")
                    setShowColorPicker(true)
                  }} />
                <div className="text-neutral-300 text-sm font-base">Function</div>
                <input type="text"
                  name="personOne.function"
                  value={prof}
                  onChange={(e) => {
                    setProf(e.target.value)
                  }}
                  onBlur={(e) => {
                    setProfPrev(e.target.value)
                  }}
                  // onBlur={handleChange}
                  className="grow shrink basis-0 py-1 text-sm font-semibold p-2.5 border border-neutral-800 bg-neutral-900 rounded-[10px] flex-col justify-center items-end inline-flex text-neutral-400 font-normal w-full" />
              </div>
              <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                <div className="text-neutral-300 text-sm font-base">Roundness</div>
                <select
                  type="select-one"
                  name="personOne.rounded"
                  value={rounded}
                  onChange={(e) => {
                    setRounded(e.target.value)
                  }}
                  className="grow shrink basis-0 py-1 p-2.5 border border-neutral-700 bg-neutral-900 rounded-[10px] flex-col justify-center inline-flex text-right text-neutral-300 text-1 text-smase w-full">
                  <option selected={"none" === rounded} value="none">none</option>
                  <option selected={"sm" === rounded} value="sm">sm</option>
                  <option selected={"md" === rounded} value="md">md</option>
                  <option selected={"lg" === rounded} value="lg">lg</option>
                  <option selected={"xl" === rounded} value="xl">xl</option>
                  <option selected={"2xl" === rounded} value="2xl">2xl</option>
                  <option selected={"3xl" === rounded} value="3xl">3xl</option>
                  <option selected={"full" === rounded} value="full">full</option>
                </select>
              </div>
            </div>
          </div>
          <br />
        </div> */}

        {/* {showColorPicker && <div id="small-modal" tabIndex="-1" className="fixed flex justify-center items-center w-full p-2 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black/70">
          <div className="max-w-sm max-h-full">
            <div className="rounded-lg bg-neutral-950 border border-neutral-800 overflow-clip shadow-[0_0px_100px_0px_#000000]">
              <div className="flex items-center justify-between p-0 px-2 rounded-t bg-gradient-to-b from-neutral-900 to-neutral-950">
                <h3 className="text-xs my-2 font-medium text-gray-50 dark:text-neutral-300">
                  Choose Color
                </h3>
                <button
                  onClick={(e) => {
                    setShowColorPicker(false)
                  }}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-800 hover:text-gray-900 rounded-lg text-sm w-4 h-4 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-800 dark:hover:text-neutral-300" data-modal-hide="small-modal">
                  <svg className="w-2 h-2 m-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="rounded-sm justify-center items-center inline-flex p-0 m-0">
                {colorsArr.map((color, index) => (
                  <div className="flex-col justify-center items-center inline-flex">
                    {gradArr.map((grad) => (
                      <button
                        className={`w-4 h-4 bg-${color}-${grad} flex-col justify-center items-center flex`}
                        onClick={(e) => {
                          setColorPickerSelection(`${color}-${grad}`)
                          setChangeColor(!changeColor)
                        }}>
                      </button>
                    ))}
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>} */}

      </div>
    </>
  );

}
