import React from 'react'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'

const Slide = (obj) => {

    const isInitialMount = useRef(true);

    const [preview, setPreview] = useState(obj.obj.preview);
    const [previewInitial, setPreviewInitial] = useState(obj.obj.preview);
    const [imgPreview, setImgPreview] = useState(obj.obj.imgPreview);
    const [imgPreviewInitial, setImgPreviewInitial] = useState(obj.obj.imgPreview);
    const [imgUrl, setImagUrl] = useState(obj.obj.img)

    useEffect(() => {
        setPreview(obj.obj.preview)
        if (isInitialMount.current) {
        } else {
            setPreviewInitial(true)
            if (obj.obj.preview) {
            setImagUrl(obj.obj.img)
            }
            gsap.fromTo(document.getElementById("slideDiv"),
                { y: preview ? '0%' : '-100%', },
                { y: preview ? '-100%' : '0%', duration: 1, ease: 'power1.inOut' }
            );
            gsap.fromTo(document.getElementById("imgDiv"),
                { y: preview ? '0%' : '-200%', },
                { y: preview ? '-200%' : '0%', duration: 1.5, ease: 'power2.inOut' }
            );
        }
    }, [obj.obj.preview])

    useEffect(() => {

        if (isInitialMount.current || !obj.obj.preview) {
            isInitialMount.current = false;
        } else {
            setImagUrl(obj.obj.img)
            gsap.fromTo(document.getElementById("imgElement"),
                { scale: 1.1 },
                { scale: 1, duration: 1, ease: 'power4.inOut' }
            );
        }
    }, [obj.obj.img])

    return (
        <div id="slideDiv"
            className={`${previewInitial ? `` : `hidden`} absolute left-0 top-0 w-[1920px] h-[1080px] px-2.5 py-[50px] justify-center items-start gap-2.5 inline-flex`}
            style={{
                backgroundColor: `rgba(${obj.obj.bgcolor.rgb.r}, ${obj.obj.bgcolor.rgb.g}, ${obj.obj.bgcolor.rgb.b}, ${obj.obj.bgcolor.rgb.a})`,
            }}>
            <div id="imgDiv"
                className={`w-[1820px] h-[722px] flex-col justify-center items-center inline-flex overflow-clip `}
                style={{
                    border: `1px solid rgba(${obj.obj.bordercolor.rgb.r}, ${obj.obj.bordercolor.rgb.g}, ${obj.obj.bordercolor.rgb.b}, ${obj.obj.bordercolor.rgb.a})`,
                    borderRadius: `${obj.obj.rounded}px`
                }}>
                {obj.obj.img &&
                    <img id="imgElement" className="object-cover w-[1820px] h-[720px]" src={imgUrl} />}
            </div>
        </div>
    )
}

export default Slide